.brand-edit-input {
    width: 100%;
    height: 40px;
    color: #0d0d0d;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border: 1px solid #BABABA;
    border-radius: 5px;
}
.brand-img{
    height: 18% !important;
    width: 100%;
}
.brand-container{
    background-color:white;
    padding: 10px;
}
.delete-Icon{
    color: red;
    font-size: 20px;
}
.manageAccount{
    margin-top: 100px;
}
.addressBody{
    width:1000px;
}
.addressContent{
    margin-bottom: 25px;
    font-family: 'Roboto';
    font-style: normal;
    display: flex;
    background-color: #ffffff;
    overflow: scroll;
}
.accountHeading{
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}
.networkError{
    color: red;
}
// .addressflexes{
//     flex: 0;
//   }
.addressManagement{
    margin-top: 50px;
    font-weight: 500;
    font-size: 16px;
}
.addressbtn{
    font-weight: 500;
    font-size: 16px;
    border-style: none;
    background: #23334C;
    color: white;
    // padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    height: 30px;
    margin-bottom: 10px;
}
.tableBox{
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    padding: 15px;
    min-width: fit-content;
}
.tableContent{
    padding: 5px;
    width: 100%;
}
.tableRow{
    border-bottom: 1px solid #C4C4C4;
}
.fullNameColumn{
    width: 15%;
}
.addressColumn{
    width: 25%;
}
.regionColumn{
    width: 30%;
}
.tableItem{

    padding: 10px;
}
.editAddress{
    border-style: none;
    background: none;
    margin-left: 30%;
}
.editAddress:hover{
    background: #23334C;
    border-radius: 5px;
    color: white;
}
.addressSaveChanges{
    background: #23334C;
    color: white;
    border: #23334C;
    border-radius: 5px;
    width: 200px;
    height: 40px;
    margin-top: 30px;
    margin-left: 40%;
}
.addressdefault{
    background: #23334C;
    color: white;
    border: #23334C;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 600;
    padding:5px;
    height: 30px;

}
.default{
    background: #a3a3a3;
    color: white;
    border: #a5a5a5;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 600;
    padding: 5px;
    height: 30px;
    // margin-left: 20%;
    width:80px;
}

.popup-input-fields{
    width: 300px;
    height: 40px;
    border: 1px solid #C4C4C4;
}

.popup-Labels{
    font-weight: 500;
    font-size: 16px;
}
.manage-myAddress{
    background-color: white;
    padding: 40px;
    margin-top: 40px;
    margin-left: 40px;
}
.sub-labels{
    margin-top: 20px;
}
.popup-model{
    margin-top: 100px;
}
.popup-content{
    padding: 8px;
    overflow: scroll;
}
.popup-secondcol{
    margin-left: 60px;
}
.popupSaveChanges{
    background: #23334C;
    color: white;
    border: #23334C;
    width: 200px;
    height: 40px;
    margin-top: 30px;
    margin-left: 40px;
}
.popup-model{
    overflow: scroll;
}
.popup-part{
    padding-left: 20px;
    padding-right: 20px;
}
.product-Cate-price{
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color:  #000000;

}
.popup-product-img{
    height: 300px;
    width: 213px;
}
.related-Categories{
    height: 100%;
}
.product-detail-popup{
    width: 300px;
    font-size: 16px;
    font-weight: 700;
    font-family: Roboto;
}
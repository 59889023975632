.allahdainLogo{
    margin-top: 50px;
}
.orderRecievedHeading{
    margin-top: 50px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}
.thankYouText{
    margin-top: 20px;
    padding: 10px;
    color: #FFFFFF;
    background-color: #23334C;
}
.borderLeft{
    border-left: 0.2px solid rgb(183, 188, 200);
}
.orderDetailsdata{
    background-color: rgba(246, 240, 240, 0.868);
    // height: 100px;
    text-align: center;
    align-items: center;
}
.bottomText1{
    text-align: center;
    margin-top: 50px;
    line-height: 30px;
}
.bottomText2{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 60px;
    line-height: 30px;
}
.purchase-thanks{
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #23334C;
    margin-top: 20px;
}
.purchase-order-no{
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #23334C;
   
}
.delivery-order-detail{
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}
.order-box{
    background-color: #FFFFFF;
    border: 1px solid #23334C;
}
.delivery-product-img{
    width:100px;
    height :70px;
}
.delivery-date{
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}
.delivery-more-view{
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #606060;
}
.detail-account{
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}
.view-order{
    background-color: white;
    color: #000000;
    border: 1px solid #606060;
    height: 20px;
}
.confirmation-box{
    height: 59px;
    background: #FFFFFF;
    border: 1px solid #23334C;
}
.confirmation-email{
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

}
.order-summary {
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #000000;
    margin-right: 3px;
}
.order-summary1{
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 3px;
}
.continue-shopping{
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    height: 40px;
    width: 270px;
    color: white;
    background-color: #23334C;
    border-radius: 6px;
    border:none;
}
@media (max-width:"768px") {
.thankYouText{
    margin-left: 10px !important;
}
}
@media (max-width:"440px") {
    .orderRecievedHeading{
        width: 100%;
        text-align: center;
    }
}

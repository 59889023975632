.btn-upload{
    height: 30px !important;
    width: 85px;
    color: #000000;
    cursor: pointer;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #EAEAEA !important;
    border-radius: 5px;
  }
  .Sections{
    margin-left:18vw;
   height:100vh;
   background-color:#FAFAFA;
   }
   .slide-img {
    position: relative;
    width: 50%;
  }
  
  .image-slider {
    opacity: 1;
    display: block;
    height: 300px;
    width: 300px;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 46%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .slide-img:hover .image {
    opacity: 0.3;
  }
  
  .slide-img:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: red;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    cursor: pointer;
  }
  .link{
    margin-top: 10px;
    background-color: rgb(165, 165, 165);
    width: 90%;
    color:#fff ;
  }
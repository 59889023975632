.dashboard{
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.dashboard-card{
  height: 110px;
  background-color: #FFFFFF;
}
.revinew-section{
  height: 40px;
  background-color: #DFDFDF;
  text-align: center;
}
.ravinew{
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}
.t-revinew{
  text-align: center;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000000;
}
.revenue{
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;

}
.revenue-date{
  float: right;
  height: 30px;
  width: 215px;
  background-color: #DFDFDF;
}
.date{
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}
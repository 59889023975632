.wishlist-card {
    width: 100%;
    height: 222px;
    background: #FFFFFF;
    border-radius: 6px;
}

.product-wish {
    height: 189px;
    width: 122%;
}

.wish-product-name {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.off {
    color: #23334C;
    margin-left: 5%;
}

.price {
    color: #23334C;
}

.wish-product-price {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

}

.wish-price {
    font-family: Roboto;
    font-size: 14px;
    margin-top: 4%;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

}

.wish-product-desc {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #606060;
}

.wish-product-rating {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    height: 26px;
    border: none;
    color: white;
    background-color: #23334C;
}

.wish-heart {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-right: 6%;
    color: red !important;
}

.wish-heart :hover {
    color: red;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.body1{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #111;
}
.container1{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container1 .ring{
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: -30px;
    border: 4px solid transparent;
    border-top: 4px solid #24ecff;
    animation: animate 4s linear infinite;
}
@keyframes animate
{
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}
.container1 .ring::before{
    content: '';
    position: absolute;
    top: 12px;
    right: 12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #24ecff;
    box-shadow: 0 0 0 5px #24ecff33,
    0 0 0 10px #24ecff22,
    0 0 0 20px #24ecff11,
    0 0 20px #24ecff,
    0 0 50px #24ecff;
}
.container1 .ring:nth-child(2){
    animation: animate2 4s linear infinite;
    animation-delay: -1s;
    border-top: 4px solid transparent;
    border-left: 4px solid #93ff2d;
}
.container1 .ring:nth-child(2)::before{
    content: '';
    position: absolute;
    top: initial;
    bottom: 12px;
    left: 12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #93ff2d;
    box-shadow: 0 0 0 5px #93ff2d33,
    0 0 0 10px #93ff2d22,
    0 0 0 20px #93ff2d11,
    0 0 20px #93ff2d,
    0 0 50px #93ff2d;
}
@keyframes animate2
{
    0%
    {
        transform: rotate(360deg);
    }
    100%
    {
        transform: rotate(0deg);
    }
}
.container1 .ring:nth-child(3){
    animation-delay: -3s;
   position:absolute;
   top: -66.66px;
   border-top: 4px solid transparent;
   border-left: 4px solid #ef1cf8;
}
.container1 .ring:nth-child(3)::before{
    content: '';
    position: absolute;
    top: initial;
    bottom: 12px;
    left: 12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ef1cf8;
    box-shadow: 0 0 0 5px #ef1cf833,
    0 0 0 10px #ef1cf822,
    0 0 0 20px #ef1cf811,
    0 0 20px #ef1cf8,
    0 0 50px #ef1cf8;
}
.container1 p{
    position: absolute;
    color: #fff;
    font-size: 1.5em;
    font-family: Roboto;
    bottom: -85px;
    letter-spacing: 0.15em;
}
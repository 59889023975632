.contact_container {
  justify-content: center;
  height: 100vh;
  margin: 0 30% 0 30%;
}

.contact_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin: 75px 0;
}
.name_field {
  display: inline-flex;
  height: 40px;
  background: #ffffff;
  border: 1px solid #23334c;
  box-sizing: border-box;
  height: 40px;
}
.details {
  width: 100%;
}
.input_box {
  margin-bottom: 10px;
  width: 100%;
}
.input_field {
  width: 100%;
  background: #ffffff;
  border: 1px solid #23334c;
  box-sizing: border-box;
  height: 40px;
}
.labels {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #000000;
}

.ordersContent{
    font-family: 'Roboto';
    font-style: normal;
    display: flex;
    margin-bottom: 25px;
}
.accountHeading{
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}
.errorMessage{
    color: red;
}
.ordersflexes{
    flex: 0;
  }
.manageAccount{
    margin-top: 100px;
    padding-left: 3%;
    padding-right: 3%;
    overflow-x: scroll;
}
.Body{
    margin-left: 30px;
    width: 1000px;
    background-color: #ffff;
    padding: 5%;
}
.Buttons{
    float: left;
    border-style: none;
    background: #23334C;
    border-radius: 5px;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}
.Button{
    float: left;
    border-style: none;
    background: none;
}
.Button:hover{
    background: #23334C;
    border-radius: 5px;
    color: white;
}
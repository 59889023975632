.order-nav{
    margin-top: 5px;
}
.order-td{
width: 160px;
border-left: none !important;
border-right: none !important;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 14px;
letter-spacing: 0em;

}
.order-td1{
width: 160px;
border-left: none !important;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
 }
 
.ordersContent{
    font-family: 'Roboto';
    font-style: normal;
    display: flex;
    margin-bottom: 25px;
    overflow: scroll;
}
.accountHeading{
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}
.ordersflexes{
    flex: 0;
  }
.manageAccount{
    margin-top: 100px;
    padding-left: 3%;
    padding-right: 3%;
    overflow-x: scroll;
}
.ordersBody{
    margin-left: 30px;
    width: 1000px;
}
.order{
    border: 1px solid #FAFAFA;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 950px;
    height:fit-content;
    overflow: auto;
}
.orderID{
    color: #595959;
    margin-right: 10px;
}
.inlineFloat{
    display: inline;
    float: right;
}
.manageOrderButton{
    float: right;
    border-style: none;
    background: none;
}
.manageOrderButton:hover{
    background: #23334C;
    border-radius: 5px;
    color: white;
}
.orderImage{
    width: 70px;
height: 70px;
}
.orderDetail{
    margin-top: -15px;
    height: 70px;
    margin-left: 8px;
    margin-right: 40px;
    display: inline;
}
.quantityOrder{
    color: #595959;
}
.deliveryDate{
    color: #595959;
}




.top-heading {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  
    margin-bottom: 20px;
    color: #000000;
  }
  .name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  
    color: #000000;
  }
  
  .Qty {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #595959;
  }
  
  .btn-status {
    width: 100px;
    height: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: none;
    color: #F29900;
    background-color: #ffff;
  }
  
  .product-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #000000;
  }
  
  .total {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }
  
  .date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #595959;
  }
  .Top{
    margin-top: 2%;
  }
  .btn-manage {
    width: 100px;
    height: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: none;
    color: #F29900;
    background-color: #ffff;
  
  }
  .pr {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #000000;
  }
  .border{
    border: 1px solid #000000;
  }
  .hg-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
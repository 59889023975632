.sidebarContent {
    width: 230px;
    padding-top: 27px;
    padding-left:25px;
    padding-right:25px;
    left: 0px;
    background: #23334c;
    color: #fff;
    position: fixed;
    overflow: hidden;
}
.userSidebar-toggleClose{
    background-color: #23334c;
    width: 230px;
    color: #fff;
}
.usersSidebar-toggleIcon{
    font-size: 25px;
    min-width: fit-content;
}
.usersSidebar-toggleIcon:hover{
    font-size: 30px;
    cursor: pointer;
}
.profilePicture{
    border-radius: 50%;
    width: 70px;
    height: 70px;
}
.pictureName{
    padding-top: 35px;
    padding-bottom: 35px;
}
.name{
    color: #fff !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 5px !important;
    text-transform: capitalize;
}
.listItem{
    padding-left: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: -10px;
    margin-top: -10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 180px;
}
.listItem:hover{
    background:  #ffc107;
    border-radius: 5px;
    color: white !important;
}
.myOrdertext{
    font-weight: 500;
}
.myOrdertext:hover{
    color: #f9f2f2;
    background-color:  #ffc107;
}
.active-user-screen{
    background:  #ffc107;
    border-radius: 5px;
    color: white !important;
}
.listSubItem{
    padding-left: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: -10px;
    margin-top: -10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 180px;
}
.listSubItem:hover{
    background:  #ffc107;
    border-radius: 5px;

}
.userSidebar-toggleClose{
    padding-left: 200px;
   
}
.userSidebar-toggleClose:hover{
    cursor: pointer;
}